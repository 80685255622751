.shoppingCartExpired {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.shoppingCartExpiredContent {
  position: relative;
  background-color: white;
  width: 550px;
  border-radius: 5px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  display: flex;
  flex-direction: column;
}

.shoppingCartExpiredContentHeader {
  padding: 20px;
  background-color: #f5f5f5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.shoppingCartExpiredContentTitle {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 600;
}

.shoppingCartExpiredContentText {
  color: #333;
  font-size: 14px;
  margin-bottom: 10px;
}

.shoppingCartExpiredContentDivider {
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin-bottom: 10px;
  margin-top: 20px;
}

.shoppingCartExpiredContentItems {
  display: flex;
  flex-direction: column;

  gap: 10px;

  max-height: 400px;
  overflow-y: auto;
}

.shoppingCartExpiredContentContainer {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.shoppingCartExpired .cartOrderProductRow {
  padding-top: 10px;
  font-size: 14px;
}

.shoppingCartExpired .discount-price-color {
  font-size: 14px;
}

.shoppingCartCloseButton {
  position: absolute;
  top: 25px;
  right: 15px;
  cursor: pointer;
}

.shoppingCartExpiredContentContainer a {
  background: var(--shop-theme-color);
  border: 1px solid var(--shop-theme-color);
}

.shoppingCartExpiredContent .btn.btn-primary:hover {
  background-color: var(--shop-theme-color-hover);
  border-color: var(--shop-theme-color-hover);
}

.modal-title {
  color: #333;
  font-size: 18px;
}
