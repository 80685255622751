.file-explorer {
    &__container {
        background-color: $sidebar-bg;
        width: 350px;
        z-index: 999;
        position: absolute;
        top: 0px;
        left: 0px;
        padding: 15px
    }
    .file-locations {
        display: flex;
        gap: 10px;
        flex-flow: wrap;
        &__item {
            padding: 2px 5px;
            cursor: pointer;
            border: 1px solid rgb(135, 132, 132);
            color: rgb(135, 132, 132);
            font-size: 10px;
            border-radius: 4px;
            transition: all 0.3s;
            &--active {
                color: #fff;
                border-color: #fff;
            }
        }
    }
    &__items {
        overflow: auto;
        height: 150px;
        &__item {
            border-top: 1px solid #fff;
            cursor: pointer;
            gap: 10px;
            &__name {
    
            }
            &__icon {
                margin-top: -1px;
            }
            &:hover {
                background-color: $input-background;
            }
        }
    }
    &__picture-preview {
        width: 100%;
        aspect-ratio: 16/9;
    }
    &__breadcrumb {
        cursor: pointer;
    }
}