.page-list .page {
  border-radius: 6px;
  transition: all 0.3s;
}
.page-list .page:hover {
  background-color: $input-background;
  box-shadow: $input-box-shadow;
}
.page-list .page.active {
  background-color: $input-background;
}
.back-btn {
  cursor: pointer;
  width: 35px;
  height: 35px;
  border: 1px solid;
  border-radius: 6px;
  transition: all 0.3s;
}
.back-btn:hover {
  background-color: $sidebar-bg;
}
.google-preview {
  font-family: arial, sans-serif;
  background: #fff;
  padding: 10px;
  user-select: none;
}
.google-preview .title {
  color: #1a0dab;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}
.google-preview .url {
  color: green;
  font-size: 14px;
  line-height: 18px;
}
.google-preview .description {
  color: #4d5156;
  word-wrap: break-word;
  font-size: 13px;
  line-height: 18px;
}

.dropdown-menu-styling {
  display: flex;
  list-style: none;
  margin-bottom: 0 !important;
  display: flex;
  gap: 10px;
  border-radius: 3px;
  cursor: pointer;
  & > li {
    & > span {
      & > .MuiSvgIcon-root {
        color: #fff;
        font-size: 15px;
      }
    }
  }
}

.pages-button {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  font-size: 14px;
  width: auto;
  height: 20px;
  color: #fff !important;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: #363636;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.pages-button:hover {
  color: #fff !important;
}

.pages-button-hold {
  font-size: 14px;
  width: auto;
  height: 20px;

  align-items: center;
  border-radius: 3px;
  cursor: pointer;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.pages-button-active {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  background-color: #363636;
}

.noindex-label {
  display: block;
  font-size: 12px;
  font-weight: 300;
  height: auto;
  text-align: end;
  width: 180px;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 3px;
  color: #fff;
}

.seo-tag-active-button {
  background-color: #363636;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  border-radius: 3px;
}

.relation-add-button {
  width: auto;
  background-color: transparent;
  color: white;
  font-size: 14px;
  border: none;
}

.relation-filter-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 100%;
  cursor: pointer;
}

.add-relation-view {
  border-radius: 5px;
  margin: 5px 0px;
  padding: 0px 5px;
}

.relations-active-relation {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 100%;
  background-color: #363636;
  border-radius: 3px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
}

.relation-item-container {
  display: grid;
  grid-template-columns: 0.6fr 1fr 0.1fr;
  gap: 10px;
  padding: 7px 5px;
}

.website-pages-search-input {
  width: 70%;
  border: none;
  border-bottom: 1px solid rgb(214, 214, 214);
  background-color: transparent;
  color: white;
  font-size: 14px;
  outline: none;
  &::placeholder {
    color: white;
    font-size: 14px;
  }
}

.pages-language-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
  color: white;
}

.icon-container {
  display: inline-block;
  position: relative;
  margin-right: 10px;

  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.tooltip {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: white;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.3s;
  font-size: 12px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);

  /* Tooltip arrow */
  &:after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
}
