.subsidebar {
  color: #fff;
  font-size: 15px;
  z-index: 999;
  padding: 60px 10px;
  background-color: $sidebar-sub-bg;
  position: absolute;
  left: $sidebar-width;
  top: 0;
  width: 400px;
  height: calc(100% - $header-height);
  overflow-x: visible;
  overflow-y: visible;
  box-shadow: 0.7px 0px 1.6px -5px rgba(0, 0, 0, 0.026),
    1.7px 0px 3.7px -5px rgba(0, 0, 0, 0.038),
    2.9px 0px 6.4px -5px rgba(0, 0, 0, 0.047),
    4.6px 0px 10.1px -5px rgba(0, 0, 0, 0.055),
    7.1px 0px 15.6px -5px rgba(0, 0, 0, 0.063),
    11.2px 0px 24.4px -5px rgba(0, 0, 0, 0.072),
    18.5px 0px 40.5px -5px rgba(0, 0, 0, 0.084),
    37px 0px 81px -5px rgba(0, 0, 0, 0.11);
  .MuiSvgIcon-root {
    font-size: 22.5px;
  }
  p {
    margin-bottom: $builder-base-size;
  }
}
@media (max-width: 575.98px) {
  .subsidebar {
    width: calc(100% - $sidebar-width);
  }
}

input.builder-input:read-only {
  cursor: not-allowed;
}
input.global-editor__input {
  margin-bottom: 0px !important;
}
input.builder-input,
textarea.builder-input {
  appearance: auto;
  -moz-appearance: textfield;
  width: 100%;

  margin-bottom: 1px; //Borderi suuruse muutumise pärast
  padding-left: 0px;
  padding-right: 5px;
  border: 0;
  border-radius: 0px;
  border-bottom: 1px solid;
  border-color: #9b9b9b;
  background: transparent;
  resize: none;
  color: #fff;
}
input.builder-input:focus,
textarea.builder-input:focus {
  margin-bottom: 0px; //Borderi suuruse muutumise pärast
  outline: none;
  border-color: inherit;
  border-bottom: 2px solid;
  -webkit-box-shadow: inherit;
  box-shadow: inherit;
  background: transparent;
  color: #fff;
}
.subsidebar a {
  display: inline-flex;
  user-select: none;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
.hover-underline:hover {
  color: #fff;
  text-decoration: underline !important;
}
