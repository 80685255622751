.typography-list .typography-item:hover {
  background-color: #616161;
  border-radius: 6px;
  transition: all 0.3s;
}

.typography-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  li {
    cursor: pointer;
  }
}

.typograhpy-button {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  font-size: 14px;
  width: auto;
  height: 20px;
  color: #fff!important;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: #363636;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.typograhpy-button:hover {
  color: #fff!important;
}

.typography-addon-button:hover {
  border-bottom: 1px solid white;
}
