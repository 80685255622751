.api-status > span {
  border: 1px solid;
  border-radius: 4px;
  padding: 0 5px;
}
.api-status.success {
  border-color: #43c23a;
  color: #43c23a;
}
.api-status.failed {
  border-color: #e03a3a;
  color: #e03a3a;
}
.api-status.disabled {
  border-color: #686868;
  color: #686868;
}

.website-edit-button {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  font-size: 14px;
  width: auto;
  height: 20px;
  color: #fff!important;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: #363636;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.website-edit-button:hover {
  color: #fff!important;
}



.website-border-radius-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid white;
  color: white;
  -webkit-appearance: none;
  margin: 0;
/* Firefox */

  -moz-appearance: textfield;

}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none!important;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}