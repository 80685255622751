.editor-confirmation {
    &__container {
        top: 0px;
        position: absolute;
        margin: 0;
        z-index: 999999999999;
        width: 280px;
    }
    &__overlay {
        background: black;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &__form {
        min-height: 110px;
        background: #323232;
        color: #fff;
        margin: 15px;
        height: calc(100% - 30px);
        padding: 10px 15px;
        position: relative;
        display: flex;
        align-items: center;
    }
}