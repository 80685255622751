.accordion_icon_active {
  transform: rotate(180deg);
  transition: all 0.25s;
}

.accordion_icon {
  right: 0;
  position: absolute;
  transition: all 0.25s;
}

.accordion-content {
  max-height: 1000px; /* Set initial height */
  overflow: hidden;
  transition: max-height 2s ease-out;

  -ms-overflow-style: none;
  scrollbar-width: none;
}

.accordion-content::-webkit-scrollbar {
  display: none;
}

.accordion-content.expanded {
  max-height: 1000px; /* Adjust the maximum height as needed */
}

.accordion-header p {
  margin-bottom: 0rem;
}

.accordion-header span {
  margin-bottom: 0rem;
}

.accordion-content .container {
  margin: 0;
  padding: 0;
}
