.link-form {
    &__container {
        top: 0px;
        left: 0px;
        position: absolute;
        margin:0;
        z-index: 101;
        width: 100%;
    }
    &__overlay {
        background: black;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        position: absolute;
    }
    &__form {
        background: $sidebar-bg;
        position: relative;
        width: 92%;
        padding: 10px 15px;
        height: 92%;
        margin: auto;
        margin-top: 4%;
    }
    &__link-options {
        display: flex;
        flex-flow: wrap;
        gap: 10px;
    }
    &__link-options-item {
        padding: 2px 5px;
        border: 1px solid rgb(135, 132, 132);
        color: rgb(135, 132, 132);
        font-size: 10px;
        border-radius: 4px;
        transition: all 0.3s;
        &--active {
            border-color: rgb(45 215 66);
            color: rgb(45 215 66);
          }
    }
}