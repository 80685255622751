:root {
  --shop-font-family: "Roboto", sans-serif;
}

:root {
  --shop-theme-color: #359e3f;
}

:root {
  --shop-theme-color-hover: #016938;
}

:root {
  --shop-border-radius: 50px;
}
