.editor-confirmation__container__save {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 999999999999;
  background: rgba(0, 0, 0, 0.8);
  font-family: "Inter", sans-serif;
  display: none;
}
.editor-confirmation__container-open {
  display: block;
}

.editor-confirmation__content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  padding: 25px;
  border-radius: 5px;

  width: 500px;
  height: auto;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
}

.editor-confirmation__text {
  font-size: 16px;
  color: white;
  font-weight: 600;
  margin-bottom: 12px;
}
.editor-confirmation__text_description {
  margin-top: 7px;
  font-size: 12px;

  font-weight: 400;
  color: #f3f3f3;
}

.editor-confirmation__buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.editor-confirmation__buttons button {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  border: none;
  font-size: 12px;
  border-radius: 3px;
  font-weight: 400;
  padding: 8px 12px;
  border-radius: 0px;

  cursor: pointer;
}
