@import url("../ShopTheme.css");

.ProductsSortComponent {
  text-align: right;
}
.ProductsSortComponent .filterDropdownButton {
  margin-bottom: 8px;
  padding: 6px 15px;
}
.ProductsSortComponent .dropdown-container-style {
  width: 300px;
  padding: 15px;
}
.ProductsSortComponent .product_sort_icon {
  position: absolute;
}
.ProductsSortComponent .loadProducts {
  width: 100%;
}
.ProductsSortComponent .sortFilterRow {
  cursor: pointer;
}
.ProductsSortComponent .checkedIcon {
  margin-left: -10px;
  font-size: 20px;
  color: var(--shop-theme-color);
  font-weight: 600;
  position: absolute;
}
.ProductsSortComponent .perPageFilter .container {
  padding-top: 8px;
  line-height: 1.7;
}
.ProductsSortComponent.open > .dropdown-menu {
  display: block;
}
.ProductsSortComponent .dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.ProductsSortComponent .dropdown-menu {
  color: #333;
  font-size: 16px;
}
.ProductsSortComponent .dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1200;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}
.ProductsSortComponent .dropdown-menu {
  margin-top: 5px;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  -webkit-transition: 0.25s;
  -o-transition: 0.25s;
  transition: 0.25s;
}
