.language-component {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 3px;
}

.language-item-active {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 1px solid white;
}

.language-dropdown-trigger {
  display: flex;
  position: relative;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  text-transform: uppercase;

  /* margin-top: 4px; */
}

.language-dropdown {
  background-color: white;

  padding: 5px 10px;
  margin: 5px 0;

  position: absolute;

  margin-left: -5px;

  border-radius: 3px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
}
