@import "../ShopTheme.css";

html,
body {
  font-size: 15px;
}
.panel {
  margin-bottom: 22px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  /* -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05); */
  /* box-shadow: 0 1px 1px rgba(0, 0, 0, .05); */
}

.panel {
  position: relative;
  margin-bottom: 30px;
  border-width: 0;
  /* -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1); */
  /* box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .1); */
}

.panel {
  background-color: white;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.panel-title {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #424242;
}

.panel-title {
  padding-top: 30px;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  color: #757575;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: var(--shop-theme-color);
  border-radius: 10px;
}

.badge {
  padding: 3px 6px;
}

.badge-primary {
  color: #fff;
  background: var(--shop-theme-color);
}

.color-primary {
  color: var(--shop-theme-color);
}

.color-primary:hover {
  color: var(--shop-theme-color-hover);
}

.badge-square {
  border-radius: 0.215rem;
}

.panel-title-text {
  color: #333;
  font-size: 16px;
  margin-left: 10px;
}

.paymentMethodButtonTitle {
  font-size: 14px;
  border: 1px solid var(--shop-theme-color);
  font-weight: 600;
  color: var(--shop-theme-color);
  border-radius: var(--shop-border-radius);
  transition: 0.3 all ease;
}

.paymentMethodMaksekeskus {
  color: var(--shop-theme-color);
  border: 1px solid var(--shop-theme-color);
  border-radius: var(--shop-border-radius);
  font-weight: 500;
  border-color: var(--shop-theme-color);
  padding: 6px 15px;
}

.paymentMethodMaksekeskus:hover {
  background-color: var(--shop-theme-color);
  color: white;
}

.paymentMethodButton {
  color: var(--shop-theme-color);
  border: 1px solid var(--shop-theme-color);
  border-radius: var(--shop-border-radius);
  font-weight: 500;
  border-color: var(--shop-theme-color);
  padding: 6px 15px;
}

.paymentMethodButton:hover {
  background-color: var(--shop-theme-color);
  color: white;
}

.paymentMethodButtonTitle:hover {
  background-color: var(--shop-theme-color);
  color: white;
}

.panel-title-text {
  font-weight: 600;
}
.clickable {
  cursor: pointer;
}

.checkout .panel-body {
  padding: 0 30px 30px !important;
}
.checkout .personName {
  font-size: 18px;
  color: #333;
  line-height: 1.9;
}
.checkout .personPhone,
.checkout .personEmail {
  font-size: 15px;
}
.links-green {
  font-size: 14px;
  font-family: var(--shop-font-family), sans-serif;
}

.table-container {
  overflow: auto;
}

.modal-content {
  border-radius: unset;
  border: unset;
}

.modal-header {
  border-bottom: none;
}
.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}
.padding-top-15 {
  padding-top: 15px;
}
.padding-bottom-15 {
  padding-bottom: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-15 {
  margin-top: 15px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-left-15 {
  margin-left: 15px;
}

.margin-top-5 {
  margin-top: 5px;
}
.btn-round {
  border-radius: 1000px;
}

.mk-payment-options {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
}

.mk-payment-options .mk-payment-options__option img {
  margin-right: 20px;
  margin-bottom: 20px;
  transition: box-shadow 0.2s ease-in-out;
}

.mk-payment-options .mk-payment-options__option input:checked + img {
  box-shadow: 0 0 0 5px var(--shop-theme-color);
}

.mk-payment-options .mk-payment-options__option img {
  max-width: 92px;
}
#deliveryMethodFilled .btn.btn-pure.backToDeliveryMethodSelect {
  padding-left: 0px;
  margin-left: -4px;
  margin-bottom: 10px;
}
.paymentMethodNotSelected {
  display: flex;
  flex-direction: column;
}
.paymentMethodNotSelected button:not(:last-child) {
  margin-bottom: 10px;
}

.checkout .btn-primary {
  font-weight: 600;
  color: #fff;
  background: var(--shop-theme-color);
  border-color: var(--shop-theme-color);
  border-radius: 0;
  transition: all 0.2s ease-in;
}

.checkout .btn-primary:hover {
  background: var(--shop-theme-color-hover);
  border-color: var(--shop-theme-color-hover);
}

.checkout .btn-primary-outline {
  font-weight: 600;
  color: var(--shop-theme-color);
  border-color: var(--shop-theme-color);
  background: transparent;
  transition: all 0.2s ease-in;
}

.checkout .btn-primary-outline:hover {
  color: #fff;
  background: var(--shop-theme-color);
}
.receiverAddress {
  font-size: 18px;
  color: #333;
}

.receiverPhone {
  font-size: 14px !important;
}

.MuiFormControl-root label {
  font-size: 14px;
}

.MuiInputBase-root input {
  font-size: 14px;
}

.receiverEmail {
  font-size: 14px !important;
}

.receiverName {
  font-size: 18px;
}

#checkoutDeliveryPanel .addressInfo .icon,
#checkoutDeliveryPanel .receiverInfo .icon {
  color: var(--shop-theme-color);
}
#checkoutDeliveryMethodModal .col-sm-12 {
  padding-top: 15px;
}

.deliveryDescription,
.deliveryDescriptionConfirm {
  white-space: pre-wrap;
  font-size: 15px;
}
.modal-header {
  padding: 25px;
}

.modal-body {
  padding: 15px 0;
}

.modal-footer {
  padding-top: 15px;
  padding-bottom: 30px;
  border-top: none;
}

.modal-title {
  color: #333;
  font-size: 18px;
}

.modal-body,
.modal-footer {
  padding-left: 30px;
  padding-right: 30px;
}

.modal-600 {
  max-width: 600px;
}
.MuiInput-root {
  margin-top: 20px;
  margin-bottom: 20px;
}
.paymentMethodNotSelected button,
.paymentMethodSelected button {
  text-align: left;
  width: 100%;
}

.selectedPaymentMethodButtonConainer {
  padding-bottom: 20px;
}

.backToPaymentMethodSelect {
  padding-bottom: 15px;
  padding-left: 0px;
  margin-left: -4px;
}

.text-left {
  text-align: left !important;
}

.panel-footer {
  padding: 0 30px 30px;
  background-color: transparent;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.terms-section {
  max-height: 300px;
  margin-bottom: 30px;
  overflow: auto;
}

.terms-section .rights-obligations {
  margin: 0;
}

.rights-obligations {
  font-size: 15px;
}
.rights-obligations h3 {
  font-size: 20px;
  font-weight: 600;
  margin-top: 22px;
  margin-bottom: 11px;
}

.rights-obligations span {
  margin-left: 20px;
}

.shop-controlled-border-radius {
  border-radius: var(--shop-border-radius) !important;
}

.shop-controlled-color {
  background-color: var(--shop-theme-color) !important;
}

.shop-controlled-color:hover {
  background-color: var(--shop-theme-color-hover) !important;
}

.MuiInputBase-root::after {
  border-bottom: 2px solid var(--shop-theme-color) !important;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--shop-theme-color) !important;
}

.buyerModal__btn {
  font-weight: 400;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 37px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.175);
  cursor: pointer;
  font-size: 14px;
}

.buyerModal__btn--active {
  border-bottom: 2px solid var(--shop-theme-color);
}

.checkoutProductsPanelConfirmedHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: var(--shop-theme-color);
}

.checkoutProductsPanelConfirmedHeading span {
  font-size: 20px;
  font-weight: 500;

  background-color: var(--shop-theme-color);
  color: white;
}

.deliveryPickupButtonTime {
  background: transparent;
  border: 1px solid rgb(230, 230, 230) !important;
  font-weight: 400;
  width: 100px;
  height: 30px;
  color: black;
  border-radius: var(--shop-border-radius);
  padding: 2px 1px;
  font-size: 13px;
}

.deliveryPickupButtonTime-active {
  background: transparent;
  border: 1px solid var(--shop-theme-color) !important;
  font-weight: 500;
  background: var(--shop-theme-color);
  color: white;
  border-radius: var(--shop-border-radius);
  padding: 2px 1px;
  font-size: 13px;
}

.react-calendar {
  width: 320px !important;
  background: white;
  border: 0px !important;

  border-radius: 5px;
  padding: 5px !important;
}

.react-calendar__navigation__label__labelText {
  font-size: 15px !important;
  color: #424242 !important;
}

.deliveryPickupButtonCalendar {
  border: 0px solid var(--shop-theme-color);
  font-weight: 500;
  color: var(--shop-theme-color);
  padding: 2px 1px;
  border-radius: 0px !important;
  font-size: 15px;
  border-right: 1px solid #e6e6e6 !important;
  padding-right: 15px !important;
}

.react-calendar__tile--active {
  background-color: #e6e6e6 !important;
  color: black !important;
  border-radius: 3px;
  font-size: 15px !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  border-radius: 3px !important;
}

.react-calendar__tile {
  padding: 9px 2px !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.react-calendar__navigation button:disabled {
  background: transparent !important;
  color: gray !important;
}

.react-calendar__tile:disabled {
  background: transparent !important;
}

.deliveryPickupButtonTimeContainer::-webkit-scrollbar {
  display: none;
  flex-direction: column !important;
}
.deliveryPickupButtonTimeContainer {
  display: flex;
  flex-direction: column !important;
  align-items: start !important;
  padding-left: 15px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.react-calendar__month-view__weekdays__weekday {
  color: black !important;
  font-weight: 400 !important;
  font-size: 15px !important;
}

.react-calendar__tile--now {
  background-color: transparent;
  font-weight: 600 !important;
}

.deliveryPickupButtonTime {
  font-size: 15px;
}

@media screen and (max-width: 575.98px) {
  .deliveryPickupButtonCalendar {
    border: none !important;
    border-radius: none !important;
  }

  .delivery_method_timings_container {
    display: flex !important;
    flex-direction: column !important;
  }

  .deliveryPickupButtonTimeContainer {
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
  }
}
