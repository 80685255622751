$text-editor-display-none-opacity: 0.5;

@media (max-width: 575px) {
  .builder-d-none:not(:has(.text-editor)) {
    opacity: $text-editor-display-none-opacity;
  }
  .builder-d-block:not(:has(.text-editor)) {
    opacity: 1;
  }
}

@media (min-width: 576px) {
  .builder-d-sm-none:not(:has(.text-editor)) {
    opacity: $text-editor-display-none-opacity;
  }
  .builder-d-sm-block:not(:has(.text-editor)) {
    opacity: 1;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .builder-d-md-none:not(:has(.text-editor)) {
    opacity: $text-editor-display-none-opacity;
  }
  .builder-d-md-block:not(:has(.text-editor)) {
    opacity: 1;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .builder-d-lg-none:not(:has(.text-editor)) {
    opacity: $text-editor-display-none-opacity;
  }
  .builder-d-lg-block:not(:has(.text-editor)) {
    opacity: 1;
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .builder-d-xl-none:not(:has(.text-editor)) {
    opacity: $text-editor-display-none-opacity;
  }
  .builder-d-xl-block:not(:has(.text-editor)) {
    opacity: 1;
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .builder-d-xxl-none:not(:has(.text-editor)) {
    opacity: $text-editor-display-none-opacity;
  }
  .builder-d-xxl-block:not(:has(.text-editor)) {
    opacity: 1;
  }
}
