.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
}

.media-slider {
  max-width: auto;
  margin: auto;

  --slide-height: 50rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;

  @media screen and (max-width: 480px) {
    --slide-height: 25rem;
    --slide-size: 100%;
    margin-left: 15px;
    margin-right: 15px;
  }
}

.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);

  font-size: 4rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla-thumbs {
  --thumbs-slide-height: 6rem;
  margin-top: var(--thumbs-slide-spacing);
}
.embla-thumbs__viewport {
  overflow: hidden;
}
.embla-thumbs__container {
  --thumbs-slide-spacing: 0.3rem;
  display: flex;
  flex-direction: row;

  /*   margin-left: calc(var(--thumbs-slide-spacing) * -1); */
}

.embla-thumbs__container_fullscreen {
  justify-content: center;
}

.embla-thumbs__slide {
  margin-right: 5px;
  margin-top: 5px;

  flex: 0 0 35%;
  min-width: 0;
  height: 120px;
}

.embla-thumbs__slide:last-child {
  margin-right: 0;
}

.embla-thumbs__slide_fullscreen {
  margin-right: 5px;
  margin-top: 5px;

  flex: 0 0 5% !important;
  min-width: 0;
  height: auto;
}

@media (min-width: 576px) {
  .embla-thumbs__slide {
    flex: 0 0 19%;
  }
  .embla-thumbs__container_fullscreen .embla-thumbs__slide {
    flex: 0 0 8% !important;
  }
}
.embla-thumbs__slide__number {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  font-size: 1.8rem;
  font-weight: 600;
  color: var(--detail-high-contrast);
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--thumbs-slide-height);
  width: 100%;
}
.embla-thumbs__slide--selected .embla-thumbs__slide__number {
  border-radius: 2px;

  -webkit-box-shadow: inset 0px 0px 0px 1px var(--shop-theme-color);
  -moz-box-shadow: inset 0px 0px 0px 1px var(--shop-theme-color);
  box-shadow: inset 0px 0px 0px 1px var(--shop-theme-color);
}

.embla__prev {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  outline: none;
  left: 10px;
  padding-left: 8px;
  opacity: 0;
  transition: all 0.25s;
}

.embla__viewport:hover .embla__prev,
.embla__viewport:hover .embla__next {
  opacity: 1;
}

.embla__next {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 50%;
  transform: translate(0, -50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  outline: none;
  right: 10px;
  padding-right: 2px;
  opacity: 0;
  transition: all 0.5s;
}

.ProductImageFullscreenContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0;
  z-index: 99999999;
  padding-left: 30px;
  padding-right: 30px;
}

.fullscreen__embla {
  width: 100%;
  margin: auto;

  --slide-height: 70rem;
  --slide-spacing: 1rem;
  --slide-size: 100%;
}

.ProductFullscreenClose {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  cursor: pointer;
  z-index: 99999999;
}
