.app {
  height: 100vh;
  overflow: hidden;
}

.app-layout {
  height: calc(100% - $header-height);
  display: flex;
  flex-direction: row;
}
.app-content {
  height: 100%;
  flex: 1 1;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: #b0bec5;
  border-radius: 5px;
}
::-webkit-scrollbar-track {
  background: #eceff1;
}
.icon:hover {
  transition: all 0.3s;
  color: $primary-color-1;
}

/* BOOTSTRAP+ */
.form-switch .form-check-input:focus {
  box-shadow: none;
}
.form-switch.large .form-check-input {
  height: 24px;
  width: 3em;
  margin-left: -3em;
}
.form-check-input:not(:checked):focus {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(0, 0, 0, 0.25)'/></svg>");
}
.form-switch.large {
  padding-left: 3em;
}
.form-switch.large label {
  padding-left: 10px;
}
.form-check-input:checked {
  background-color: $primary-color-1;
  border-color: $primary-color-1;
}
.form-check-input:focus {
  border-color: $primary-color-1;
}
.fs-small {
  font-size: 12px;
}
.mt-n1 {
  margin-top: -0.25rem;
}
.input-group > * {
  padding-right: 5px;
}
.btn:hover,
.btn:active:focus,
.btn:focus,
input {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary {
  width: auto;
  background: var(--shop-theme-color);
  border-color: var(--shop-theme-color);
}
.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary:focus {
  background: var(--shop-theme-color-hover);
  border-color: var(--shop-theme-color-hover);
}
.btn-dark {
  background: #0e0e0e;
}
.btn-dark:hover,
.btn-dark:focus {
  background: #1d1c1c;
}

/* DRAG AND DROP UPLOAD */
.builder-upload.builder-upload-drag {
  border: 1px dashed #6a6a6a;
  transition: all 0.3s;
}
.builder-upload.builder-upload-drag:hover {
  border-color: #fff;
}
.builer-upload-drag-icon svg {
  font-size: 46px !important;
}
.builder-upload.builder-upload-drag .builder-upload-btn {
  padding: 16px 0;
  transition: all 0.3s;
}
.builder-upload.builder-upload-drag.drag-active {
  background-color: #ffffff1c;
  border-color: #fff;
}
.drag-and-drop-placeholder {
  position: absolute;
  border: dashed 1px blue;
}

/* MODAL */
// .modal-content,
// .modal-header {
//   background-color: $sidebar-bg !important;
//   color: #fff;
//   border-bottom: unset;
// }
// .modal-body {
//   /* Too much padding imo */
//   padding-bottom: 0px !important;
//   padding-top: 0px !important;
// }

/* REACT COLORFUL (COLOR PICKER) */
.react-colorful__last-control,
.react-colorful__saturation {
  border-radius: 0px !important;
}

// .sorting-placeholder-top {
//   position: absolute;
//   width: 100%;
//   height: 3px;
//   top: 0px;

//   left: 0;
//   z-index: 9999999;
//   background: rgba(0, 125, 240, 1);
// }

.builder-component a {
  text-decoration: none;
}

.builder-component___button a div {
  color: unset;
}

/* COMPONENTS */
.builder-component {
  min-height: 20px;
  position: relative;

  &.dragover {
    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 125, 240);
    -moz-box-shadow: 0px 0px 0px 1px rgba(0, 125, 240);
    box-shadow: 0px 0px 0px 1px rgba(0, 125, 240);
  }

  &:hover {
    //padding-left: 10px; // TODO: so that you could see and click on things that have no padding. Needs fixing
  }
  &__text-editor {
    overflow-wrap: break-word;
    position: relative;
    span[contenteditable] {
      outline: none;
    }
    &:hover:not(.builder-component--active)::before {
      cursor: pointer;
      content: "";
      visibility: visible;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
    }
    &.builder-component--active {
      //outline: 1px solid var(--builder-element-color);
      z-index: 2;
      //outline-offset: -1px;
      border: none !important;
    }
  }
  &__section {
    font-family: sans-serif;
  }

  &__productSlider {
    &.builder-component--active {
      z-index: 2;
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__singleProduct {
    &.builder-component--active {
      z-index: 2;
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__section {
    &.row:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-row-color);
      cursor: pointer;
    }
    &.builder-component--active.row::before {
      border: 1px solid var(--builder-row-color);
    }
    &.col:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px dashed var(--builder-col-color);
      cursor: pointer;
    }
    &.inner-section:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-row-color);
      cursor: pointer;
    }
    &.builder-component--active.col::before {
      border: unset;
      z-index: 1;
    }
  }
  &__button {
    min-width: 40px;

    &.builder-component--active {
      z-index: 2;
      // min-width: 165px;
    }

    div[contenteditable] {
      outline: none;
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__media_slider {
    &.builder-component--active {
      z-index: 2;
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__content {
    &.builder-component--active {
      z-index: 2;
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__accordion {
    &.builder-component--active {
      z-index: 2;
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__iframe {
    &.builder-component--active {
      z-index: 2;
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__language_select {
    min-height: 0px !important;
    &.builder-component--active {
      z-index: 2;
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__link {
    &.builder-component--active {
      z-index: 2;
      min-width: 165px;
    }

    div[contenteditable] {
      outline: none;
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid rgba(0, 0, 0, 0.432);
      cursor: pointer;
    }
  }
  &__products {
    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }
  &__logo {
    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__image {
    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__header {
    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__nav {
    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__nav-item {
    &.builder-component--active {
      a[contenteditable] {
        outline: none;
        display: block;
        text-align: center;
      }
    }

    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }
  &__cart {
    &.builder-component--active {
      z-index: 2;
    }

    div[contenteditable] {
      outline: none;
    }
    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }

  &__navigation-container {
    &.builder-component--active {
      z-index: 2;
    }

    div[contenteditable] {
      outline: none;
    }
    &:hover:not(.builder-component--active)::before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      border: 1px solid var(--builder-element-color);
      cursor: pointer;
    }
  }
  &--active::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    //border: 1px solid var(--builder-element-color);
    // box-shadow: 0px 0px 17px 8px rgb(0 0 0 / 20%);

    -webkit-box-shadow: 0px 0px 0px 1px rgba(0, 125, 240);
    -moz-box-shadow: 0px 0px 0px 1px rgba(0, 125, 240);
    box-shadow: 0px 0px 0px 1px rgba(0, 125, 240);
  }
}

.builder-component:has(.add-new-element) {
  padding-bottom: 50px !important; // added so that add element would be visible
}
.global-editor * {
  -webkit-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  -o-transition: border 0.2s linear, color 0.2s linear, width 0.2s linear,
    background-color 0.2s linear;
  transition: all 0.2s ease-in;
}

// .ContentRenderer:has(.container:first-of-type .col .global-editor) {
//   margin-top: 120px !important; // added so that add element would be visible
// }

#BuilderContent:has(.builder-component--active)::before {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  background-color: #ffffff80;
}
#BuilderContent:has(.builder-component--active)
  .builder-component:not(.builder-component--active)::before {
  content: "";
  z-index: 1;
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  //background-color: #ffffff75;
}

@include media-breakpoint-only(xs) {
  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
  }
}

.notification-container {
  padding: 15px 15px;
  bottom: 0px;
  top: auto;
}
