$body-bg: #fff;

$primary-color-1: #616161;

$sidebar-width: 40px;
$sidebar-bg: #323232;
$sidebar-icon-paddings: 10px 8px;
$sidebar-sub-bg: #222222;

$sidebar-sub-width: 350px;

$header-height: 45px;

$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

$input-background: #444444;

$input-background: #454445;
$input-box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
  inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
$input-border-radius: 3px;

:root {
  --builder-row-color: #fff;
  --builder-col-color: #fff;
  --builder-element-color: #fff;
}
