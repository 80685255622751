.builder-column {
  &__container {
    // min-width: 623px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
  &__item {
    padding: 0 5px;
    background-color: $input-background;
    color: rgb(135 132 132);
    border-radius: 4px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.3s;
    user-select: none;
    box-shadow: $input-box-shadow;
    &--active {
      border-color: rgb(45 215 66);
      color: rgb(45 215 66);
    }
  }
}
