.global-editor {
  font-size: 15px;
  font-family: sans-serif;
  font-weight: 300;
  text-shadow: none !important;
  .MuiSvgIcon-root {
    font-size: $builder-base-size * 1.5;
  }
  &__menu {
    line-height: normal;
    position: absolute;
    min-width: 100%;
    z-index: 99;
    padding: 0;
    left: 0px;
  }
  &__top-menu {
    background-color: $sidebar-sub-bg;
    width: fit-content;
    display: flex;
    flex-wrap: wrap;

    background-color: transparent !important;
    &__item {
      height: 28px;
      width: 28px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 3px;
      box-shadow: $input-box-shadow;
      background-color: #333333;
      border-radius: $input-border-radius;
      color: #fff;
      & > svg {
        height: 20px;
      }
      &--active {
        background-color: #444444;
      }

      &:hover {
        background-color: #444444;
      }
    }
    &__item:last-child {
      cursor: auto;
      & > svg {
        cursor: pointer;
      }
    }
  }
  &__bottom-menu {
    width: fit-content;

    background-color: #333333;
    border-radius: 3px;
    box-shadow: $input-box-shadow;
    user-select: none;
    color: #fff;
    font-size: 12px;
    &:has(.fixed-parent-height) {
      //   overflow: hidden;
      //   height: 28px;
      padding-left: 5px !important;
      padding-right: 5px !important;
      min-height: 30px;
    }
  }
  &__input {
    border-bottom: 0px !important;
    width: auto;
    padding-right: 0px !important;
    text-align: center;
  }
}

.add-new-element {
  font-size: 12px;
  user-select: none;
  bottom: -19px;
  left: 0px;
  z-index: 2;
  position: absolute;
  padding: 0;
  width: max-content;
  color: #fff;
  background-color: $sidebar-sub-bg;
  text-align: left;
  // width: 110px;
  > :first-child {
    border-bottom: 1px solid #585858;
  }
  .MuiSvgIcon-root {
    font-size: 13px;
  }
  > * {
    padding: 0 7.5px;
  }
  > *:hover {
    background-color: $sidebar-bg;
  }
}

.add-new-element-buttons {
  font-size: 12px;
  line-height: 19px !important; // Otherwise element style overwrites
  user-select: none;

  justify-content: space-between;
  align-items: center;

  bottom: -19px;
  left: 0px;
  z-index: 2;
  position: absolute;
  padding: 0;
  width: max-content;
  color: #fff;
  text-align: left;
  .MuiSvgIcon-root {
    font-size: 13px;
  }
  .add-new-element-button {
    padding: 0px 3px;
    transition: background-color 0.3s;
    background-color: $sidebar-sub-bg;

    border-radius: 3px;
    cursor: pointer;
    background-color: $input-background;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: $input-box-shadow;
    &.colB {
      background-color: #333333;
    }
    &.rowB {
      background-color: #333333;
    }
    &.elementB {
      background-color: #333333;
    }
  }
  .add-new-element-button:hover {
    background-color: $sidebar-bg;
  }
}

.add-header {
  top: 0px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 999;
  min-width: 120px;
  width: max-content;
  text-align: center;
  user-select: none;
  font-size: 12px;
  padding: 2px 6px;
  color: #fff;
  background-color: #222222;
  border-radius: 0px 0px 5px 5px;
}

.add-footer {
  display: flex;
  align-items: center;
  border-radius: 5px 5px 5px 5px;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: 0px;
  width: fit-content;
  text-align: center;
  user-select: none;
  font-size: 12px;

  color: #fff;
  background-color: #222222;
}

#tooltip {
  color: white;
  font-weight: bold;
  padding: 0px;
  border-radius: 4px;
  font-size: 90%;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
