.spacing-menu-row {
  overflow-x: auto;
  flex-wrap: nowrap;
  & > *:not(:last-child):not(:first-child) {
    min-width: 80px;
  }
  & > :last-child {
    min-width: 42px;
  }
  &::-webkit-scrollbar {
    height: 5px;
  }
}

.spacing-menu_input {
  width: 40px !important;
  height: 20px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px !important;
  cursor: pointer;
  background-color: $input-background !important;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: $input-box-shadow;
}

.spacing-menu-items::-webkit-outer-spin-button,
.spacing-menu-items::-webkit-inner-spin-button {
  opacity: 1 !important;
}

input[type="number"]::-webkit-inner-spin-button {
  opacity: 1 !important;
}

.spacing-menu-items {
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  flex-direction: column;
}
