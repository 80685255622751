.delete-confirm-container {
  display: flex;
  height: 100%;
}

.delete-confirm-modal-container {
  position: absolute;
  width: 370px;
  background-color: #333;
  border-radius: 3px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  padding: 15px;
  z-index: 999999;
  right: 0px;
}

.delete-confirm-modal {
  position: absolute;
  width: 270px;
  background-color: #333;
  border-radius: 3px;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  padding: 15px;
  z-index: 999999;
  right: 0px;
}

.delete-confirm-heading {
  color: #fff;
  font-size: 14px;
  margin-bottom: 14px;
  margin-bottom: 4px;
}

.delete-confirm-delete {
  background-color: #e03a3a;
  color: #fff;
  font-size: 14px;
  border: none;
  border-radius: 3px;
  padding: 4px 5px;
  cursor: pointer;
  margin-right: 5px;
}

.delete-confirm-cancel {
  background-color: #363636;
  font-size: 14px;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 0px 5px;
  cursor: pointer;
  & :hover {
    text-decoration: underline;
  }
}
