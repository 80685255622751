.template-list {
  .template-group {
    position: relative;
    overflow: hidden;
    height: 38px;
    &.show {
      height: auto;
    }
  }
  .template-use:not(:empty) {
    border: 1px solid #fff;
    font-size: 12px;
    border-radius: 4px;
    padding: 1px 5px;
  }
  .template > label {
    margin-left: 20px;
  }
  .template:hover > label {
    text-decoration: underline !important;
  }
  .template:has(.dropdown-trigger:hover) > label {
    text-decoration: none !important;
  }
}

.templates-addon-button:hover {
  border-bottom: 1px solid white;
}

.templates-button {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  font-size: 14px;
  width: auto;
  height: 20px;

  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: #363636;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.template-input-box {
}
