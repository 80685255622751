.color-box {
  width: 34px;
  height: 34px;
}
.picker .popover {
  top: 30px;
  left: -1px;
  position: absolute;
}
.color-picker-value {
  background: #444444;
  color: #fff;
  height: 34px;
  border: none;
  width: 107px;
  outline: none;
}
.delete-icon {
  cursor: pointer;
  color: $primary-color-1;
  font-size: 18px;
}
.delete-icon:hover {
  filter: brightness(1.2);
}

.colors-button {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  font-size: 14px;
  width: auto;
  height: 20px;
  color: #fff!important;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: #363636;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.colors-button:hover  {
  color: #fff!important;
}


.color-header {
  margin-top: 40px;
  font-weight: bold;
}
