@import url("../ShopTheme.css");

.product-services {
  background-color: #f5f5f5;

  padding: 10px;
  border-radius: 5px;
  display: flex;
  gap: 25px;
  flex-direction: column;
}

.product-services-heading {
  font-size: 20px;
  color: var(--shop-theme-color);
  font-weight: 400;
  text-align: left;
  padding: 10px;
}
