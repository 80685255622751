.bubble-menu {
  background-color: #333333;
  z-index: 999999999999;
  padding: 3px 5px;
  border-radius: 3px;
  display: flex;
  max-width: auto;
  gap: 2px;
  align-items: center;

  /* translate: translateX(-100%); */
}

.bubble-menu__item {
  color: white;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  max-height: 20px;
  height: 20px;
  display: flex;
  align-items: center;
}

.bubble-menu__item--active {
  color: white;
  background-color: #444;
  border: none;
  outline: none;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  border-radius: 3px;
  height: 20px;
  max-height: 20px;
  display: flex;
  align-items: center;
}

.bubble-menu__input {
  font-size: 16px;
  height: none;
  width: auto;
  font-weight: bold;
  background-color: none;
  border: none;
  outline: none;
  color: white;
  background-color: transparent;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
}

.builder-input-bubble-menu[value] {
  font-weight: normal;
  color: white;
  max-height: 30px;
  font-size: 12px;
  font-family: "sans-serif";
  font-style: normal;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.6;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  white-space: normal;
  word-break: normal;
  word-spacing: normal;
}

.font-editor-select-bubble {
  background-color: transparent;
  border: none;
  color: white;
  width: auto;
  max-width: 43px;
  font-size: 11px;
  height: 20px;
  background-color: #444;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
}

.font-editor-select-bubble option:checked {
  background-color: #444;
  font-size: 11px !important;
}

.font-editor-select-bubble option:hover {
  background-color: #444;
}

.font-editor-select-bubble option {
  background-color: #444;
  font-size: 11px;
}

.bubble-menu__link {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  position: absolute;
  width: auto;

  background-color: #333;
  border-radius: 3px;
  backdrop-filter: blur(5px);
}

.bubble-menu_link_item {
  background-color: #333;
  border-radius: 3px;
  border: none;
  font-size: 9px;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}

.bubble_menu__link_select {
  background-color: transparent;
  border: none;
  color: white;
  margin: 0;
  width: 50px;
  font-size: 11px;
  background-color: #444;
  border-radius: 3px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  text-align: center;
}

.bubble_menu__link_select option:checked {
  background-color: #444;
  font-size: 11px !important;
}

.bubble_menu__link_select option:hover {
  background-color: #444;
}

.bubble_menu__link_select option {
  background-color: #444;
  font-size: 11px;
}

.link-menu_input {
  color: white;
  border: none;
}
.ProseMirror:focus {
  outline: none;
}

.tippy-box {
  width: max-content;
}
