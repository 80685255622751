.editor-modal {
  &__container {
    top: 0px;
    height: 250px;
    left: 0px;
    position: absolute;
    margin: 0;
    z-index: 101;
    width: 280px;
    min-height: 160px;
  }
  &__overlay {
    background: black;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &__form {
    background: #323232;
    color: #fff !important;
    margin: 15px;

    padding: 10px 15px;
    position: relative;
    display: flex;
  }
  a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  .cancelButton {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
  }
  * {
    text-align: left;
  }
}
