@import "../ShopTheme.css";
body {
  /*    font-size: 10px;*/
  color: #333;
  font-weight: 300;
  line-height: 1.57142857;
}
body,
html {
  height: 100%;
}

.productcol:nth-child(3n + 1) {
  clear: left;
}
.productcol {
  width: 33.33333333%;
  float: left;
}
@media (max-width: 1200px) {
  .productcol:nth-child(3n + 1) {
    clear: unset;
  }
  .productcol:nth-child(2n + 1) {
    clear: left;
  }
  .productcol {
    width: 50%;
  }
}
@media (max-width: 768px) {
  .productcol:nth-child(1n + 1) {
    clear: left;
  }
  .productcol {
    width: 100%;
  }
}

.categories ul li {
  padding: 2.5px 0;
  font-size: 15px;
  font-weight: 600;
}

.categories ul {
  padding: 10px;
  list-style-type: none;
}

.categoryDropdown .panel-container {
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
}

.categories .category-name {
  transition: color 0.25s ease-in;
}

.categories .dropdown-item.selected,
.categories .category-name.selected {
  color: var(--shop-theme-color);
}

.categories .dropdown-item {
  padding: 0;
  font-size: 15px;
  font-weight: 600;
}
.products_container .dropdown-item.active,
.products_container .dropdown-item:hover {
  color: var(--shop-theme-color);
}

.categories .dropdown-item.selected,
.categories .category-name.selected,
.category-name.active,
.category-name:hover {
  color: var(--shop-theme-color);
}

.categoryDropdown a {
  color: #333;
}

.product-name {
  color: #333;
  font-size: 5.75rem;
  font-size: 17.5px;
}

.product-name a {
  color: #333;
}

.productPrice {
  color: #553921;
  font-weight: 500;
  font-size: 16px;
  padding-top: 3px;
  padding-bottom: 12px;
}

.dataContainer .productPrice {
  margin-bottom: 0;
  padding-bottom: 1.75rem;
  font-size: 1.75rem;
  font-weight: 600;
  color: #333;
}

.web-product {
  transition: all 0.3s ease-in;
}

.web-product:hover .product-info-overlay {
  opacity: 1;
}

.web-product:hover {
  border: 1px solid var(--shop-theme-color);
}
.web-product {
  border: 1px solid transparent;
  margin: 5px;
  text-align: center;
}

.web-product .product-img {
  display: block;
  position: relative;
  width: 100%;
  height: 280px;
}

.web-product {
  margin: 15px;
}

.padding-10 {
  padding: 10px !important;
}

.cats-title {
  margin-bottom: 11px;
  margin-top: 11px;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.web-product img {
  position: relative;
  visibility: hidden;
}

.web-product img:after {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--shop-theme-color);
  opacity: 0.1;
}

.sticky {
  position: sticky;
  top: 0;
}

.product-info-overlay {
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 1px);
  padding: 1rem;
  opacity: 0;
  color: #fff;
  text-align: left;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 0.3s ease-in;
  font-size: 15px;
}

.MuiFormLabel-root {
  font-family: var(--shop-font-family), sans-serif !important;
}

.product-info-overlay b {
  font-weight: 600;
}

@media (min-width: 576px) {
  .products_container {
    max-width: inherit;
  }
}

@media (min-width: 768px) {
  .products_container {
    max-width: inherit;
  }
  .products_dataContainer {
    padding-left: 2rem !important;
  }
  .products_contact-page form {
    max-width: 500px;
    margin: 0 auto;
  }
}

@media (min-width: 992px) {
  .products_container {
    max-width: inherit;
  }
  .products_d-md-none {
    display: none;
  }
}

/* @media (min-width: 1200px) {
  .products_container {
    
  }
} */

.products_container {
  font-family: var(--shop-font-family), sans-serif;
  padding-right: 15px;
  padding-left: 15px;
  margin-left: auto;
  margin-right: auto;
}

.products_container .col-md-3,
.products_container .col-md-9 {
  padding-right: 15px;
  padding-left: 15px;
}

.card.itprCard {
  border: none;
}
.card.itprCard .card-footer {
  border: none;
  background-color: unset;
}

.products_container .btn-primary {
  font-weight: 600;
  color: #fff;
  background: var(--shop-theme-color);
  border-color: var(--shop-theme-color);
  border-radius: 0;
  transition: all 0.2s ease-in;
}

.products_container .btn-primary:hover {
  background: var(--shop-theme-color-hover);
  border-color: var(--shop-theme-color-hover);
}

.products_container .btn-primary-outline {
  font-weight: 600;
  color: var(--shop-theme-color);
  border-color: var(--shop-theme-color);
  background: transparent;
  transition: all 0.2s ease-in;
}

.categories h5 a {
  font-size: 20px;
  color: var(--shop-theme-color);
  font-weight: 400;
}

.categories .panel-heading {
  border-bottom: 1px solid var(--shop-theme-color);
}

.productsResults .panel {
  margin: 0;
}

#productsPanel .card-body {
  padding: 30px 0 !important;
}

.MuiPagination-ul-center ul {
  justify-content: center;
}

.MuiPagination-root .Mui-selected {
  background-color: var(--shop-theme-color) !important;
  border-color: var(--shop-theme-color);
  color: #fff;
}
.MuiPagination-root li a:hover {
  background: #f5f5f5;
}



.btn {
  font-size: 14px;
}

/* Prodct Services component style */
