.link-menu_input {
  width: auto;
  height: 20px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px !important;
  cursor: pointer;
  background-color: $input-background;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: $input-box-shadow !important;
}

.link-menu_input_text {
  background-color: $input-background !important;
  min-width: 100px !important;
  border: none;
  color: white;
}

.color-white {
  color: white !important;
}

.web_input_bg {
  background-color: $input-background !important;
  color: white;
}

.dropdown-parent-grid {
  display: grid;
  grid-template-columns: 75px 100px 100px 100px 25px;
  margin-top: 7px;
  align-items: center;
  gap: 3px;
}
