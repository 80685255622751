.builder-dropdown {
  background: $sidebar-bg;
  color: #fff;
  padding: 0px;
  min-width: auto;
  z-index: 999999999;
  font-family: sans-serif;
  font-size: 12px;

  &__item {
    color: #fff;
    font-size: 12px;
    padding: 2px 6px;
    cursor: pointer;

    &--active {
      background: $sidebar-sub-bg;
    }
  }

  &__item:hover,
  &__item:focus {
    color: #fff;
    background: $sidebar-sub-bg;
  }
}
