.languages-button {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  font-size: 14px;
  width: auto;
  height: 20px;
  color: #fff !important;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: #363636;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.languages-button:hover {
  color: #fff !important;
}

.langageDropdownOpen {
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
}
