.productServiceCartItem {
  display: grid;
  grid-template-columns: 1.25fr 1fr 1fr 1fr;
}

.productsServiceCartItemImage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  margin-right: 10px;
  background-color: white;
  border-radius: 5px;
}

.productServiceCartItemName {
  display: flex;
  flex-direction: column;

  margin-left: 4px;
  font-size: 14px;
  grid-column: span 3;
}

.productServiceCartItemQuantity {
  display: flex;
  padding: 0 4px;
  align-items: center;
  justify-content: space-between;

  width: 90px;
}

.productServicesCartButton {
  background-color: white;
  border-radius: 4px;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
}
