.template-edit-overlay {
  position: fixed;
  padding: 60px;
  bottom: 0;
  right: 0;
  width: 69%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .template-edit-overlay {
    padding: 20px;
  }
}

.template-edit-overlay__content {
  overflow: scroll;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  border-radius: 5px;
  padding: 10px;
}

.template-edit-overlay__close {
  position: absolute;
  top: 55px;
  right: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  width: auto;
  background-color: #333;
  padding: 10px;
  color: white;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  &:hover {
    background-color: #444;
  }
}

.template-edit-overlay__content > *:first-child {
  max-height: 100% !important;
}
