.color-picker-position {
  background-color: #222;
  box-shadow: $input-box-shadow;
  border-radius: 3px;
  padding: 10px;
}

.color-picker {
  &__container {
    z-index: 99;
    bottom: 19px;
    background: $sidebar-bg;
    padding: 8px !important;
    cursor: auto;
  }
  &__save-overlay {
    width: 100%;
    padding: 15px;
    height: 100%;
    background: black;
    opacity: 0.7;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 99;
  }
  &__save-modal {
    z-index: 999;
    left: 0px;
    position: absolute;
    margin: 15px;
    width: calc(100% - 30px);
    top: 22%;
    background-color: #323232;
    font-size: 13px;
  }
  &__input,
  &__input:focus {
    background: #444444 !important;
    border-bottom: 0px !important;
    margin-bottom: 0px !important;
  }
  &__title {
    font-size: 14px;
  }
  &__letter {
    position: absolute;
    bottom: -19px;
    margin: auto;
    left: 0px;
    right: 0px;
    text-align: center;
  }
  &__swatch-item {
    cursor: pointer;
    height: 18px;
    width: 18px;
    &__parent {
      background: #fff;
      /*margin: 4px 2px;*/
      background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill-opacity=".05"><path d="M8 0h8v8H8zM0 8h8v8H0z"/></svg>');
    }
    &--active {
      //Võid selle ringi teha :D
      height: 20px;
      width: 20px;
      border: 2px solid;
    }
  }
}

.color-picker .react-colorful {
  height: 210px;
  width: unset;
  min-width: 200px;
}
// Transparency
.color-picker .react-colorful__alpha.react-colorful__last-control {
  margin-top: 10px;
}
.color-picker .react-colorful__saturation {
  border-bottom: 0px;
}
.color-picker .react-colorful__hue,
.color-picker .react-colorful__alpha.react-colorful__last-control,
.color-picker .react-colorful__saturation {
  overflow: hidden;
}

.color-picker-dropdown {
  position: absolute;
  background-color: #323232;
  list-style: none;
  border-radius: 3px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  z-index: 99999;
  box-shadow: $input-box-shadow;
}

.hidden {
  display: none;
}
