@import url("../ShopTheme.css");

.personName,
.personEmail,
.badge-brown,
.dropdown-menu,
.receiverAddress,
.receiverName,
.receiverEmail,
.col-xs-12.padding-top-20 h4,
.checkoutOrderProductRow,
.modal2 .modal-title,
.panel-title-text,
.btn.btn-round.btn-brown.confirmPersonData,
.container-fluid.page-header-title,
#filterModal .sortFilter,
#filterModal .sortFilter h3,
#filterModal .categoryFilter,
#filterModal .categoryFilter h3,
#filterModal .perPageFilter,
#headerMenuModal .h4,
.perPageFilter.margin-top-15 b .navbar-default .navbar-toolbar > li > a,
.btn.btn-brown.btn-round.filterDropdownButton,
.font-page-header a.web-category-link,
.cartOrderProductRowName,
.cartOrderProductQuantity,
.cartOrderProductRowPrice,
.panel-title.inline-block.padding-left-10 {
  font-weight: 600;
}
.page.checkout {
  font-family: var(--shop-font-family), sans-serif;
}

.cartOrderProductRowName span {
  font-weight: 400;
}

.cart-sidePanel {
  width: 100%;
  font-family: var(--shop-font-family), sans-serif;
  display: inline-flex;
  justify-content: end;
  pointer-events: none;
  right: 0;
  top: 0;

  position: fixed;
  -webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  height: 100vh;
  z-index: 999999999;
}

.cart-sidePanel:has(.sidePanel.visible) {
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: all;
}

.pull-right {
  float: right;
}
.cart-sidePanel .sidePanel {
  height: 100%;
  /* width: 800px; */
  max-width: 500px;
  display: none;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 30px;
}

.cart-sidePanel h4 {
  font-size: 18px;
}

.cart-sidePanel .sidePanel.visible {
  display: block !important;
  width: 100vw;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  color: inherit;
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #424242;
}

.inline-block {
  display: inline-block !important;
}

.cart-sidePanel .panel-title {
  padding-top: 17.5px;
  padding-left: 0 !important;
  font-weight: 600;
}

.btn.icon {
  padding: 10px;
  font-size: 16px;
  line-height: 1em;
}

.icon.pull-right {
  margin-left: 0.3em;
}

.cart-sidePanel .btn-pure {
  margin-top: 0.25rem;
  margin-top: 12.5px;
  padding-right: 0;
  width: 25px;
  height: 25px;
  font-weight: 600;
  color: #808080;
}

.padding-top-20 {
  padding-top: 20px !important;
}

.table-container {
  overflow: auto;
}

.table {
  font-size: 14px;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 22px;
}

.cart-sidePanel .btn-primary {
  font-weight: 600;
  color: #fff;
  padding-top: 6px;
  padding-bottom: 6px;
  background: var(--shop-theme-color);
  border-color: var(--shop-theme-color);
  border-radius: 0;
  transition: all 0.2s ease-in;
}

.cartOrderProductRow,
.checkoutOrderProductRow {
  border-top: 1px solid #e4e4e4;
}

.tablerow-no-border td,
.tablerow-no-border tbody,
.tablerow-no-border th {
  border-top: none !important;
  border-bottom: none !important;
}

.cartOrderProductRowPrice,
.cartOrderProductQuantity,
.cartOrderProductPrice,
.checkoutOrderProductQuantity,
.checkoutOrderProductPrice,
.checkoutOrderProductRowQuantity,
.checkoutOrderProductRowPrice {
  white-space: nowrap;
}

.cartOrderProductRow,
.checkoutOrderProductRow {
  border-top: 1px solid #e4e4e4;
}

.cartOrderProductRow td,
.checkoutOrderProductRow td {
  vertical-align: top;
}

.cartOrderProductRowName,
.checkoutOrderProductRowName {
  font-weight: 600;
}

.checkoutConfirmOrderButton {
  width: 100% !important;
}

.page-header-title {
  font-weight: 600;
  font-size: 1.5rem;
}

.cartOrderProductRowDelete {
  text-align: end;
  justify-content: end;
  cursor: pointer;
}

.cartSidepanelProductImage {
  object-fit: contain;
}

.cartOrderProductQuantity {
  display: flex;
  gap: 8px;
  align-items: start;
  justify-content: end;
}

.cartOrderProductQuantity div span {
  font-weight: 400;
}

.cartQuantityIcon {
  background-color: #f1f1f1;
  border-radius: 2px;
}

.cartOrderProductRowPrice {
  display: flex;
  justify-content: end;
  text-align: end;
}

.cartOrderProductRowPrice span {
  width: 100%;
  font-weight: 400;
}

.cartOrderProductRowName,
.cartOrderProductQuantity,
.cartOrderProductRowPrice {
  color: #333;
  font-size: 15px;
}

.panel-title.inline-block.padding-left-10 {
  padding-left: 22px !important;
}

#cartSidepanel .col-xs-12.padding-top-20 h4 {
  padding-left: 10px;
}

.cartOrderProductRowName {
  grid-column: span 2;
}

.cartOrderProductRowImage {
  grid-row: 1 / span 3;
  display: none;
}

.cartOrderProductRowDiscount {
  grid-column: span 3;

  font-weight: 500;
}

@media (min-width: 576px) {
  .d-sm-table-cell {
    display: table-cell !important;
  }
}

@media (min-width: 420px) {
  .cartOrderProductRowImage {
    grid-row: 1 / span 2;
    display: grid;
  }

  .cartOrderProductRowDiscount {
    grid-column: span 2;

    font-weight: 500;
  }

  .cartOrderProductRowName {
    grid-column: span 1;
  }
}

.cart-sidePanel .clickable {
  font-size: 14px;
  cursor: pointer;
}

.cart-sidePanel .clickable2 {
  cursor: pointer;
}
.fs-14 {
  font-size: 14px !important;
}

.shop-controlled-border-radius {
  border-radius: var(--shop-border-radius) !important;
}

.cart-sidePanel a {
  color: white !important;
}

.cartOrderProductRow {
  display: grid;
  grid-template-rows: 0.25fr 0.5fr 25px;
  grid-template-columns: 0.75fr 1.5fr 1fr 1fr 0.25fr;
}

.cartOrderProductRowDiscount span {
  font-weight: 500;
}

.cardOrderProductRowDiscountPrice {
  text-align: right;
}

.cardOrderProductRowDiscountPrice span {
  font-weight: 500;

  width: 100%;
}

.shoppingCartExpiredContentItems .cartOrderProductRow {
  padding-top: 10px;
}
