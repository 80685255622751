.text-editor-options > *:not(:first-child) {
  margin: 0 5px;
}
.text-editor-options svg {
  font-size: 18px !important;
  &.active {
    color: #8d8d8d;
  }
}

.undoRedoInactive {
  color: rgb(135 132 132);
}

.font-height {
  &__container {
    position: absolute;
    bottom: -28px;
    left: -8px;
    background-color: $sidebar-sub-bg;
    & > * {
      padding: 2px;
    }
  }
  &__buttons {
    cursor: pointer;
    padding: 2px 7px;
  }
}

.font-menu_input {
  width: auto;
  height: 20px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: $input-background;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: $input-box-shadow;
}

.font-menu_hover:hover {
  cursor: pointer;
  height: 20px;
  box-shadow: $input-box-shadow;
  background-color: $input-background;
  border-radius: 3px;
}
