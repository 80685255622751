$builder-base-size: 15px;

.b-mt-0 {
  margin-top: 0px !important;
}
.b-mt-1 {
  margin-top: calc(0.25 * $builder-base-size) !important;
}
.b-mt-2 {
  margin-top: calc(0.5 * $builder-base-size) !important;
}
.b-mt-3 {
  margin-top: calc(1 * $builder-base-size) !important;
}
.b-mt-4 {
  margin-top: calc(1.5 * $builder-base-size) !important;
}
.b-mt-n1 {
  margin-top: calc(-0.25 * $builder-base-size) !important;
}
.b-mt-n2 {
  margin-top: calc(-0.5 * $builder-base-size) !important;
}
.b-mt-n3 {
  margin-top: calc(-1 * $builder-base-size) !important;
}
.b-mt-n4 {
  margin-top: calc(-1.5 * $builder-base-size) !important;
}
.b-mb-0 {
  margin-bottom: 0px !important;
}
.b-mb-1 {
  margin-bottom: calc(0.25 * $builder-base-size) !important;
}
.b-mb-2 {
  margin-bottom: calc(0.5 * $builder-base-size) !important;
}
.b-mb-3 {
  margin-bottom: calc(1 * $builder-base-size) !important;
}
.b-mb-4 {
  margin-bottom: calc(1.5 * $builder-base-size) !important;
}
.b-ms-0 {
  margin-left: 0px !important;
}
.b-ms-1 {
  margin-left: calc(0.25 * $builder-base-size) !important;
}
.b-ms-2 {
  margin-left: calc(0.5 * $builder-base-size) !important;
}
.b-ms-3 {
  margin-left: calc(1 * $builder-base-size) !important;
}
.b-ms-4 {
  margin-left: calc(1.5 * $builder-base-size) !important;
}
.b-me-0 {
  margin-right: 0px !important;
}
.b-me-1 {
  margin-right: calc(0.25 * $builder-base-size) !important;
}
.b-me-2 {
  margin-right: calc(0.5 * $builder-base-size) !important;
}
.b-me-3 {
  margin-right: calc(1 * $builder-base-size) !important;
}
.b-me-4 {
  margin-right: calc(1.5 * $builder-base-size) !important;
}
.b-me-5 {
  margin-right: calc(3 * $builder-base-size) !important;
}
.b-mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.b-mx-1 {
  margin-left: calc(0.25 * $builder-base-size) !important;
  margin-right: calc(0.25 * $builder-base-size) !important;
}
.b-mx-2 {
  margin-left: calc(0.5 * $builder-base-size) !important;
  margin-right: calc(0.5 * $builder-base-size) !important;
}
.b-mx-3 {
  margin-left: calc(1 * $builder-base-size) !important;
  margin-right: calc(1 * $builder-base-size) !important;
}
.b-mx-4 {
  margin-left: calc(1.5 * $builder-base-size) !important;
  margin-right: calc(1.5 * $builder-base-size) !important;
}
.b-my-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.b-my-1 {
  margin-top: calc(0.25 * $builder-base-size) !important;
  margin-bottom: calc(0.25 * $builder-base-size) !important;
}
.b-my-2 {
  margin-top: calc(0.5 * $builder-base-size) !important;
  margin-bottom: calc(0.5 * $builder-base-size) !important;
}
.b-my-3 {
  margin-top: calc(1 * $builder-base-size) !important;
  margin-bottom: calc(1 * $builder-base-size) !important;
}
.b-my-4 {
  margin-top: calc(1.5 * $builder-base-size) !important;
  margin-bottom: calc(1.5 * $builder-base-size) !important;
}

.b-pt-0 {
  padding-top: 0px !important;
}
.b-pt-1 {
  padding-top: calc(0.25 * $builder-base-size) !important;
}
.b-pt-2 {
  padding-top: calc(0.5 * $builder-base-size) !important;
}
.b-pt-3 {
  padding-top: calc(1 * $builder-base-size) !important;
}
.b-pt-4 {
  padding-top: calc(1.5 * $builder-base-size) !important;
}
.b-ps-0 {
  padding-left: 0px !important;
}
.b-ps-1 {
  padding-left: calc(0.25 * $builder-base-size) !important;
}
.b-ps-2 {
  padding-left: calc(0.5 * $builder-base-size) !important;
}
.b-ps-3 {
  padding-left: calc(1 * $builder-base-size) !important;
}
.b-ps-4 {
  padding-left: calc(1.5 * $builder-base-size) !important;
}
.b-p-0 {
  padding: 0px !important;
}
.b-p-1 {
  padding: calc(0.25 * $builder-base-size) !important;
}
.b-p-2 {
  padding: calc(0.5 * $builder-base-size) !important;
}
.b-p-3 {
  padding: calc(1 * $builder-base-size) !important;
}
.b-p-4 {
  padding: calc(1.5 * $builder-base-size) !important;
}
.b-p-5 {
  padding: calc(3 * $builder-base-size) !important;
}

.b-m-0 {
  margin: 0px !important;
}
.b-m-1 {
  margin: calc(0.25 * $builder-base-size) !important;
}
.b-m-2 {
  margin: calc(0.5 * $builder-base-size) !important;
}
.b-m-3 {
  margin: calc(1 * $builder-base-size) !important;
}
.b-m-4 {
  margin: calc(1.5 * $builder-base-size) !important;
}
.b-m-5 {
  margin: calc(3 * $builder-base-size) !important;
}

.b-py-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.b-py-1 {
  padding-top: calc(0.25 * $builder-base-size) !important;
  padding-bottom: calc(0.25 * $builder-base-size) !important;
}
.b-py-2 {
  padding-top: calc(0.5 * $builder-base-size) !important;
  padding-bottom: calc(0.5 * $builder-base-size) !important;
}
.b-py-3 {
  padding-top: calc(1 * $builder-base-size) !important;
  padding-bottom: calc(1 * $builder-base-size) !important;
}
.b-py-4 {
  padding-top: calc(1.5 * $builder-base-size) !important;
  padding-bottom: calc(1.5 * $builder-base-size) !important;
}
.b-px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.b-px-1 {
  padding-left: calc(0.25 * $builder-base-size) !important;
  padding-right: calc(0.25 * $builder-base-size) !important;
}
.b-px-2 {
  padding-left: calc(0.5 * $builder-base-size) !important;
  padding-right: calc(0.5 * $builder-base-size) !important;
}
.b-px-3 {
  padding-left: calc(1 * $builder-base-size) !important;
  padding-right: calc(1 * $builder-base-size) !important;
}
.b-px-4 {
  padding-left: calc(1.5 * $builder-base-size) !important;
  padding-right: calc(1.5 * $builder-base-size) !important;
}
.b-pb-0 {
  padding-bottom: 0px !important;
}
.b-pb-1 {
  padding-bottom: calc(0.25 * $builder-base-size) !important;
}
.b-pb-2 {
  padding-bottom: calc(0.5 * $builder-base-size) !important;
}
.b-pb-3 {
  padding-bottom: calc(1 * $builder-base-size) !important;
}
.b-pb-4 {
  padding-bottom: calc(1.5 * $builder-base-size) !important;
}
.b-pe-0 {
  padding-right: 0px !important;
}
.b-pe-1 {
  padding-right: calc(0.25 * $builder-base-size) !important;
}
.b-pe-2 {
  padding-right: calc(0.5 * $builder-base-size) !important;
}
.b-pe-3 {
  padding-right: calc(1 * $builder-base-size) !important;
}
.b-pe-4 {
  padding-right: calc(1.5 * $builder-base-size) !important;
}
.b-gap-0 {
  gap: 0 !important;
}

.b-gap-1 {
  gap: calc(0.25 * $builder-base-size) !important;
}
.b-gap-2 {
  gap: calc(0.5 * $builder-base-size) !important;
}
.b-gap-3 {
  gap: calc(1 * $builder-base-size) !important;
}
.b-gap-4 {
  gap: calc(1.5 * $builder-base-size) !important;
}
.b-gap-5 {
  gap: calc(3 * $builder-base-size) !important;
}
.b-fs-5 {
  font-size: calc(1.125 * $builder-base-size) !important;
}
.b-fs-6 {
  font-size: calc(0.9 * $builder-base-size) !important;
}

.b-container,
.b-container-fluid,
.b-container-xxl,
.b-container-xl,
.b-container-lg,
.b-container-md,
.b-container-sm {
  --bs-gutter-x: 0px;
  --bs-gutter-y: 0px;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.b-row {
  --bs-gutter-x: 22.5px;
  --bs-gutter-y: 0px;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.b-row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.b-dropdown-menu {
  border-radius: 0px !important;
}

.b-btn {
  --bs-btn-padding-x: 7.5px;
  --bs-btn-padding-y: 3.75px;
  --bs-btn-font-size: 13.5px;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.6;
  --bs-border-radius: 5.625px;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: var(--bs-border-width);
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: var(--bs-border-radius);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 3.75px
    rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .b-btn {
    transition: none;
  }
}
.b-btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.b-btn-check + .b-btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.b-btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.b-btn-check:focus-visible + .b-btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow);
}
.b-btn-check:checked + .b-btn,
:not(.b-btn-check) + .b-btn:active,
.b-btn:first-child:active,
.b-btn.active,
.b-btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
}
.b-btn-check:checked + .b-btn:focus-visible,
:not(.b-btn-check) + .b-btn:active:focus-visible,
.b-btn:first-child:active:focus-visible,
.b-btn.active:focus-visible,
.b-btn.show:focus-visible {
  box-shadow: var(--bs-b-btn-focus-box-shadow);
}
.b-btn:disabled,
.b-btn.disabled,
fieldset:disabled .b-btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
}

.b-form-check {
  min-height: 21.5px !important;
  margin-bottom: 1.875px !important;
}

.COLUMN {
  display: block !important;
}

// .b-d-none::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;

//   color: white;
//   display: none;
//   background-color: rgba(0, 0, 0, 0.25);
//   backdrop-filter: blur(3px);
//   justify-content: center;
//   align-items: center;
//   font-size: 13px;

//   text-transform: uppercase;
// }

// .b-d-sm-none::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;

//   color: white;
//   display: none;
//   background-color: rgba(0, 0, 0, 0.25);
//   backdrop-filter: blur(3px);
//   justify-content: center;
//   align-items: center;
//   font-size: 13px;

//   text-transform: uppercase;
// }

// .b-d-md-none::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;

//   color: white;
//   display: none;
//   background-color: rgba(0, 0, 0, 0.25);
//   backdrop-filter: blur(3px);
//   justify-content: center;
//   align-items: center;
//   font-size: 13px;

//   text-transform: uppercase;
// }

// .d-mb-d-none::after {
//   content: "";

//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;

//   color: white;
//   display: none;
//   background-color: rgba(0, 0, 0, 0.25);
//   backdrop-filter: blur(3px);
//   justify-content: center;
//   align-items: center;
//   font-size: 13px;

//   text-transform: uppercase;
// }

// .b-d-lg-none::after {
//   content: "";

//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;

//   color: white;
//   display: none;
//   background-color: rgba(0, 0, 0, 0.25);
//   backdrop-filter: blur(3px);
//   justify-content: center;
//   align-items: center;
//   font-size: 13px;

//   text-transform: uppercase;
// }

// .b-d-xl-none::after {
//   content: "";

//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;

//   color: white;
//   display: none;
//   background-color: rgba(0, 0, 0, 0.25);
//   backdrop-filter: blur(3px);
//   justify-content: center;
//   align-items: center;
//   font-size: 13px;

//   text-transform: uppercase;
// }

// @media screen and (min-width: 1200px) {
//   .b-d-xl-none::after {
//     display: flex;
//   }
// }

// @media screen and (min-width: 992px) and (max-width: 1199.98px) {
//   .b-d-lg-none::after {
//     display: flex;
//   }
// }

// @media screen and (min-width: 768px) and (max-width: 991.98px) {
//   .b-d-md-none::after {
//     display: flex;
//   }
// }
// @media screen and (min-width: 768px) and (max-width: 991.98px) {
//   .d-mb-d-none::after {
//     display: flex;
//   }
// }

// @media screen and (min-width: 576px) and (max-width: 767.98px) {
//   .b-d-sm-none::after {
//     display: flex;
//   }
// }

// @media screen and (max-width: 575.98px) {
//   .b-d-sm-none::after {
//     display: flex;
//   }
// }
