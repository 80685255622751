.button-menu_input {
    width: auto;
    height: 20px;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    cursor: pointer;
    background-color: #454445;
    padding-left: 5px;
    padding-right: 5px;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  }