.builder-border-menu {
  &__container {
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;

    &:first-child:nth-last-child(1) > :first-child {
      width: 44px;
    }
    > :first-child {
      width: 100px;
    }
  }
  .color-picker__swatch-item {
    height: 14px;
    width: 14px;
  }
}

.border-menu_input {
  width: auto;
  height: 20px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: $input-background;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: $input-box-shadow;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
