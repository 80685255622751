.content-width-menu_input {
  width: auto;
  height: 20px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: $input-background;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: $input-box-shadow;
}
