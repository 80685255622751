.builder__content {
  &-container {
    position: relative;
    width: 100%;
    height: 100%;
    min-width: calc(100vw - 40px);
    min-height: calc(100vh - 45px);

    margin: auto;
  }
}

.singleHeaderOption {
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: #333;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
}
