.megamenu_component {
  position: absolute;
  width: 1000px;
  z-index: 9999;
  height: 1000px;
  left: 10px;
  right: 0px;
  top: 50px;

  margin-left: auto;
  margin-right: auto;
  opacity: 0;

  transition: opacity 0.075s linear, visibility 0s linear 0.075s; /* Transition opacity and delay visibility */
}

.megamenu_component.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.075s linear;
}
