.background-item > img {
  aspect-ratio: 16 / 9;
  width: 72px;
}

.background-grid-container {
  grid-template-columns: auto auto auto;
  min-width: 144px;
  background: $sidebar-sub-bg;
}
.background-grid-item {
  color: #fff;
  font-size: 12px;
  padding: 3px 4px;
  transition: background-color 0.3s;
}
.background-grid-item--active {
  background: $input-background;
}
.background-grid-item:hover {
  background-color: $input-background;
}

.background-item-option {
  position: relative;
  padding-right: 5px;
}

.background-item-title-menu {
  position: absolute;
  background-color: #333;
  padding: 8px;
  border-radius: 5px;
  box-shadow: $input-box-shadow;
  z-index: 999999;
}

.add-media-dropdown {
  background-color: #333;
  padding: 5px 5px;
  width: max-content;
  box-shadow: $input-box-shadow;
  border-radius: 3px;
  cursor: pointer;
}

.add-media-dropdown:hover {
  background-color: #444;
}

.background-video {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
  gap: 5px;
}

.background-video__controls {
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 7px 40px;

  grid-auto-flow: row;
  padding: 0px 8px;
}

.background-menu_input {
  width: auto;
  height: 20px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: $input-background;
  padding-left: 5px;
  padding-right: 5px;
  box-shadow: $input-box-shadow;
}

.background-menu-dropdown {
  position: absolute;
  z-index: 99999;
  background-color: #333;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  list-style: none;
  padding: 10px;
  margin: 0px;
  margin-top: 2px;
}
