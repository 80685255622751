.sidebar {
  min-width: $sidebar-width;
  background-color: $sidebar-bg;
  position: relative;
  z-index: 999;

  .MuiSvgIcon-root {
    font-size: 22.5px;
  }
}
.sidebar,
.subsidebar {
  font-size: 15px;
}

.subsidebar {
  position: fixed;
  height: 100vh;
  overflow-x: scroll;
  z-index: 1000;
}

.sidebar > ul {
  position: fixed;
  top: 40px;
  left: 0;
  z-index: 999;
  list-style-type: none;
  cursor: pointer;
}
.sidebar > ul > li {
  padding: $sidebar-icon-paddings;
  transition: background-color 0.2s ease-in-out;
}
.sidebar > ul > li.active {
  background-color: $sidebar-bg;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  border-radius: 3px;
}
.sidebar svg {
  color: #fff;
}

.sidebar-save-btn {
  border: none;
  color: white;
  box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.8),
    inset 0px 0.5px 0.5px rgba(255, 255, 255, 0.12);
  font-size: 14px;
  width: auto;
  height: 30px;

  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  background-color: #05ad21;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
}

.sidebar-cancel-btn {
  border: none;
  color: white !important;
  font-size: 14px;
  width: auto;
  height: 30px;
  cursor: pointer;
}

.sidebar-cancel-btn:hover {
  color: #fff !important;
  text-decoration: none !important;
}
